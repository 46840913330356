import Vue from 'vue';
import {mapActions} from 'vuex';
import {Switch} from 'vant';
import {Toast } from 'vant';
import { Dialog } from 'vant';

// 全局注册
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Switch);
export default {
  name: 'orderSure',
   beforeRouteLeave(to, from, next){
    if(to.name == "paySuccess"){
       this.$router.push({name:"Mine"});
    } else{
      next();
    }
  },
  data() {
    return {
      name: '',
      aid:'',
      phone: '',
      address: '',
      orderList: [],
      num: 0,
      checked: true,
      totalMoney: 0,
      show: true,
      isempty: false,
      accountBanlance:0,
      detailMsg:''
    };
  },

  created() {
    this.getLists();

    if(this.$route.query.payType==1){
      this.getNowDetail()

    }else{
      this.getShopList({
        pids: localStorage.getItem('pid'),
      }).then((res) => {
        if(res.code==200){
        this.orderList = res.data.content;
        this.totalMoney = res.data.totalMoney;
        this.accountBanlance=res.data.accountBanlance
        for (let i = 0; i < this.orderList.length; i++) {
          this.num += parseInt(this.orderList[i].productNumber);
        }

        }else{
          Toast(res.msg);
        }

      });
    }


  },
  methods: {
    ...mapActions('CrinsuranceMall', ['CorinsuranceshopListDetail']),
    ...mapActions('address', ['defaultAddress', 'delAddress', 'addAddress', 'editAddress', 'findAddress', 'addressDetail']),
    ...mapActions('shopList', ['getShopList', 'editCart', 'delCart','checkShop1','checkShop','subOrder']),
    back() {
      if(this.$route.query.payType==0&&this.$route.query.undo==0){
         this.$router.push({name:'shopCarList'})
      }else if(this.$route.query.payType==1&&this.$route.query.undo==0){
        this.$router.push({name:'corwelDetail',query:{pid:localStorage.getItem('pid')}})
     }else{
      // this.$router.go(-1);
      this.$router.push({name:'shopCarList'})
     }

    },
    toPay() {
      var that=this
      if(this.isempty){
        Toast('请先新增选择收货地址');
      }else{
        if(this.$route.query.payType==0){
          this.checkShop({
            aid:this.aid,
            pids:localStorage.getItem('pid'),
            payType:this.$route.query.payType,
          }).then(res=>{
            if(res.code==200){
            this.paymentMethod(this.$route.query.orderNo)

            }else{
              Toast(res.msg)
            }
          });
        }else{
          this.checkShop1({
            aid:this.aid,
            pid: localStorage.getItem('pid'),
            payType:this.$route.query.payType,
          }).then(res=>{
            if(res.code==200){
              this.paymentMethod(res.data.orderNo)
            }else{
              Toast(res.msg)
            }
          });

        }
      }

    },
    paymentMethod(orderNo){
      var that=this
      Dialog.confirm({
        message: '确认支付吗',
      })
        .then(() => {
          // on confirm

          if(parseFloat(this.totalMoney)-parseFloat(this.accountBanlance)>0 &&this.checked){
            Toast({message:'您的积分不足，无法使用积分支付',onClose:function(){
              that.$router.push({name:'myOrder'})
            }
            });

          }else{
            var ischeck;
            if(this.checked){
               ischeck=0;
               var data={
                pids:localStorage.getItem('pid'),
                price:this.totalMoney,
                type:ischeck,
                remark:this.detailMsg,
                aid:this.aid,
                payType:this.$route.query.payType,
                orderNo:orderNo
              }
               this.subOrder(JSON.stringify(data)).then((res) => {
                if(res.code==200){
                  Toast({message:'支付成功',onClose:function(){
                    localStorage.removeItem('pid')
                    that.$router.push({name:'paySuccess'})
                  }})

                }else{

                  Toast({message:res.msg,onClose:function(){
                    that.$router.push({name:'myOrder'})
                  }
                  });

                }

               });
            }else{
              ischeck=1;
              var data={
                pids:localStorage.getItem('pid'),
                price:this.totalMoney,
                type:ischeck,
                remark:this.detailMsg,
                aid:this.aid,
                payType:this.$route.query.payType,
                orderNo:orderNo
              }
              this.subOrder(JSON.stringify(data)).then((res) => {

                if(res.code==200){
                  var thedata=JSON.parse(res.data.param)
                  //微信支付
                  that.onBridgeReady(thedata)

               /*  if (typeof WeixinJSBridge == "undefined"){
                  if( document.addEventListener ){
                      document.addEventListener('WeixinJSBridgeReady', that.onBridgeReady(thedata), false);
                  }else if (document.attachEvent){
                      document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady(thedata));
                      document.attachEvent('onWeixinJSBridgeReady', that.onBridgeReady(thedata));
                  }*/
              /* }else{
                that.onBridgeReady(thedata)
               }*/

                }else{
                  Toast({message:res.msg,onClose:function(){
                    that.$router.push({name:'myOrder'})
                  }
                  });

                }
               });
            }
          }
        })
        .catch(() => {
          // on cancel
          that.$router.push({name:'myOrder'})
        });

    },
   onBridgeReady(thedata){

      var that=this
    WeixinJSBridge.invoke(
      'getBrandWCPayRequest', {
         "appId":thedata.appId,     //公众号名称，由商户传入
         "timeStamp":thedata.timeStamp,         //时间戳，自1970年以来的秒数
         "nonceStr":thedata.nonceStr, //随机串
         "package":thedata.package,
         "signType":thedata.signType,         //微信签名方式：
         "paySign":thedata.paySign //微信签名
      },
      function(res){


      if(res.err_msg == "get_brand_wcpay_request:ok" ){
        localStorage.removeItem('pid')
        that.$router.push({name:'myOrder'})
        // 使用以上方式判断前端返回,微信团队郑重提示：
        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
      }else{
        Toast({message:'支付失败',onClose:function(){
          that.$router.push({name:'myOrder'})
        }
        });

      }
   });

   },
    add(){
      this.$router.push({path: '/newAddress',query:{back:0,payType:this.$route.query.payType}});
    },
    toAddress(){
      this.$router.push({path: '/addressList',query:{back:0,payType:this.$route.query.payType}});
    },
    getLists(){
      this.findAddress().then((res) => {
        if (res.code == 200) {
          if (res.data.length>0) {
            this.isempty = false;
            this.show = true;
             if (this.$route.query.id == undefined) {
              this.defaultAddress().then(res => {
                if (res.code == 200) {
                  this.aid=res.data.shoppingAddressId
                  this.name = res.data.shoppingAddressUsername;
                  this.phone = res.data.shoppingAddressUserPhone;
                  this.address = res.data.shoppingAddressArea + res.data.shoppingAddressDetail;
                } else {
                  this.findAddress().then((res) => {
                    if (res.code == 200){
                      this.aid=res.data[0].shoppingAddressId
                      this.name = res.data[0].shoppingAddressUsername;
                      this.phone = res.data[0].shoppingAddressUserPhone;
                      this.address = res.data[0].shoppingAddressArea + res.data[0].shoppingAddressDetail;
                        }
                  });
                }
              })
               }
              else {
              this.addressDetail(this.$route.query.id).then(res => {
                if (res.code == 200) {
                  this.aid=res.data.shoppingAddressId
                  this.name = res.data.shoppingAddressUsername;
                  this.phone = res.data.shoppingAddressUserPhone;
                  this.address = res.data.shoppingAddressArea + res.data.shoppingAddressDetail;
                } else {
                }
              })
              }
        } else {
          this.isempty = true;
          this.show = false
        }

      }

      });
    },
    getNowDetail(){
      this.CorinsuranceshopListDetail({flProductId:localStorage.getItem('pid')}).then(res=>{
        if(res.code==200){
          var data={
            flProductHomeImage:res.data.flProductHomeImage,
            flProductName:res.data.flProductName,
            flProductPrice:res.data.flProductPrice,
            productNumber:1
          }
          var content=[]
          content.push(data)
          this.orderList = content;
          this.totalMoney = res.data.flProductPrice;
          this.accountBanlance=res.data.accountBanlance
          this.num=1
        }else{
          Toast(res.msg)
        }

      })

    },

  }
}


