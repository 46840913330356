import { render, staticRenderFns } from "./orderSure.vue?vue&type=template&id=83a92172&scoped=true&"
import script from "./orderSure.js?vue&type=script&lang=js&"
export * from "./orderSure.js?vue&type=script&lang=js&"
import style0 from "./orderSure.css?vue&type=style&index=0&id=83a92172&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83a92172",
  null
  
)

export default component.exports